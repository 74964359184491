import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clone from '../../../utils/clone';
import { moveElmToFront } from '../../../utils/move-to-front';
import { useCompactView } from '../../hooks';

import Editor from '../../Editor/Editor';
import { Toggle } from '../../Toggle';
import uiSchema from '../../Editor/ui-schema';
import { mainNavigationContext } from '../../../store/mainNavigation';
import { saveQueueContext } from '../../../store/saveQueue';
import { spinnerContext } from '../../../store/spinner';
import spinnerReducers from '../../../store/spinner/spinner-reducers';

import reducers from '../../../store/saveQueue/save-queue-reducers';
import { mainDataContext } from '../../../store/mainData';
import stripEmptyNestedObjects from '../../../utils/strip-empty-nested-objects';
import { Tabs } from '../../Tabs/Tabs';
import { Box, Button, Container, Paper } from '@mui/material';
import { schemaContext } from '../../../store/schema';

function CountryBasedForm({ dataKey, data, schema }) {
  const tabs = Object.keys(data[dataKey]);
  const sortedTabs = moveElmToFront(tabs, '*');
  const [activeTab, setActiveTab] = useState(null);
  const spinnerDispatch = useContext(spinnerContext)[1];

  useEffect(() => {
    setActiveTab(sortedTabs[0]);
  }, []);

  const schemaRoot = schema.properties[dataKey].additionalProperties;
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);
  const { compactView, setCompactView } = useCompactView();

  const handleTabsClick = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const handleSubmit = (formData) => {
    const nextData = { ...data[activeTab], ...formData };
    setActiveTab(sortedTabs[0]);

    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        rowChange: { type: 'editRule' },
        body: stripEmptyNestedObjects(nextData),
      },
    });
  };

  function getEditor() {
    const nextData = data[dataKey][activeTab];
    const schemaProperties = schema.properties[dataKey].additionalProperties;
    return (
      <>
        {schema && nextData && (
          <Box sx={{ p: 1 }}>
            <Box sx={{ mb: 2 }}>
              <Toggle />
            </Box>
            <Editor
              className={`${dataKey}-editor ${
                schemaProperties.type === 'array' ? 'array-specific-editor' : ''
              }`}
              uiSchema={uiSchema}
              formContext={{ nextData, path: [] }}
              initialData={schemaProperties.type === 'array' ? nextData : nextData}
              handleSubmitForm={handleSubmit}
              dataKey={dataKey}
              selectedSchema={
                schemaProperties.type === 'array' ? schemaProperties : schemaProperties
              }
            />
          </Box>
        )}
      </>
    );
  }

  function addDomain(domain) {
    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        rowChange: { type: 'add' },
        body: {
          _id: domain,
          comment: '',
          brandedFaresEnabled: true,
          useExclusions: true,
          exclusions: {
            exclusionTicketingAirlines: [],
            exclusionMarketingAirlines: [],
            exclusionAirports: {
              departures: [],
              arrivals: [],
            },
          },
          inclusions: {
            inclusionTicketingAirlines: [],
            inclusionMarketingAirlines: [],
            inclusionAirports: {
              departures: [],
              arrivals: [],
            },
          },
        },
      },
    });
    setActiveTab(sortedTabs[0]);
  }

  function deleteDomain(domain) {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        rowChange: { type: 'deleteDomain' },
        domainId: domain,
      },
    });
    setActiveTab(sortedTabs[0]);
  }

  return (
    <>
      {activeTab && sortedTabs && (
        <Container sx={{ px: { xs: 0 }, maxWidth: { xs: 'initial' } }}>
          <Paper sx={{ px: 2 }}>
            <Box className="app__content container-fluid">
              <Box className="content--full-width">
                <Tabs
                  tabs={sortedTabs}
                  onClick={handleTabsClick}
                  displayComponent={getEditor()}
                  selectedTab={activeTab}
                  addDomain={addDomain}
                  deleteDomain={deleteDomain}
                />
              </Box>
            </Box>
          </Paper>
        </Container>
      )}
    </>
  );
}

CountryBasedForm.propTypes = {
  data: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
};

export default CountryBasedForm;
