import MaterialTable from '@material-table/core/dist';
import React, { useContext, useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import './material-table.scss';
import {
  Edit,
  Delete,
  FileCopy,
  Search,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  FilterList,
} from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { head, isObject } from 'lodash';
import clone from '../../utils/clone';
import { columnsTypes } from './columnsTypes';
import { RulesContext } from '../contexts';
import { renderSimple, renderArray, renderObject } from './customTableCellRenderings';
import generateUiSchema from '../Editor/generateUiSchema';

const sortColumns = (dataKey, schemaRoot, uiSchemaForSelected) => {
  const schemaProperties = schemaRoot.properties[dataKey].items.properties;

  const allColumnsTypesObject = columnsTypes.reduce((acc, obj) => {
    acc[obj.field] = obj; // Use a specific key from the object (e.g., `id`) as the object key
    return acc;
  }, []);

  const filteredColumns = Object.entries(schemaProperties).map(([key, value]) => {
    if (allColumnsTypesObject.hasOwnProperty(key)) {
      return allColumnsTypesObject[key];
    }
    if (value.type === 'string') {
      return {
        tooltip: value.title || key,
        title: value.title || key,
        field: key,
        name: value.title,
        filtering: true,
        render: (rowData) => renderSimple(rowData, key),
      };
    }
    if (value.type === 'integer') {
      return {
        tooltip: value.title || key,
        title: value.title || key,
        field: key,
        name: value.title,
        filtering: true,
        render: (rowData) => renderSimple(rowData, key),
      };
    }
    if (value.type === 'number') {
      return {
        tooltip: value.title || key,
        title: value.title || key,
        field: key,
        name: value.title,
        filtering: true,
        render: (rowData) => renderSimple(rowData, key),
      };
    }
    if (value.type === 'array') {
      return {
        tooltip: value.title || key,
        title: value.title || key,
        field: key,
        name: value.title,
        filtering: true,
        render: (rowData) => renderArray(rowData, key),
      };
    }
    if (value.type === 'object') {
      return {
        tooltip: value.title || key,
        title: value.title || key,
        field: key,
        name: value.title,
        filtering: true,
        render: (rowData) => renderObject(rowData, key),
      };
    }
    return {
      tooltip: value.title || key,
      title: value.title || key,
      field: key,
      name: value.title,
      filtering: true,
      render: (rowData) => (
        <>
          <Typography color={({ palette }) => palette.info.main} variant="body2">
            {JSON.stringify(rowData[key])}
          </Typography>
        </>
      ),
    };
  });

  if (uiSchemaForSelected[dataKey]) {
    let columnOrder = uiSchemaForSelected[dataKey].items['ui:order'];
    const hasStar = columnOrder.indexOf('*') !== -1;

    for (const key in schemaProperties) {
      if (columnOrder.indexOf(key) === -1 && hasStar) {
        const starIndex = columnOrder.indexOf('*');
        columnOrder.splice(starIndex, 0, key);
      } else if (columnOrder.indexOf(key) === -1 && !hasStar) {
        columnOrder = [...columnOrder, key];
      }
    }

    const sortedColumns = [
      ...filteredColumns.sort(
        (a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field)
      ),
    ];

    const sortedColumnsNoStar = sortedColumns.filter((e) => e !== '*');
    return sortedColumnsNoStar;
  }

  return filteredColumns;
};

const formatExceptions = (data, schemaRoot) => {
  let nextData = [];

  data.forEach((item) => {
    item.schemaRoot = schemaRoot;
    if (isObject(item) && item.hasOwnProperty('exceptions')) {
      if (item.exceptions.length > 0) {
        item.exceptions.forEach((exception) => {
          exception.id = item._id;
          if (!exception.hasOwnProperty('parentId')) {
            exception.parentId = item['_id'];
          }
          nextData = [...nextData, exception];
        });
      }
    }
  });
  const combinedData = [...data, ...nextData];
  return combinedData;
};

function MaterialTableComponent({
  formattedData,
  label,
  dataKey,
  schemaRoot,
  className,
}) {
  const [filter, setFilter] = useState({});
  const uiSchemaForSelected = generateUiSchema(schemaRoot, dataKey);

  const columns = [...sortColumns(dataKey, schemaRoot, uiSchemaForSelected)];
  const { editRow, addRow, deleteRow, copyRow } = useContext(RulesContext);
  const [rowJSON, setRowJSON] = useState([]);
  const dataHasExceptions = schemaRoot.hasOwnProperty('exceptions');

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search color="action" {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  };

  useEffect(() => {
    const parsedJSON = clone(formattedData);
    const formattedJSON = formatExceptions(parsedJSON, schemaRoot);
    formattedData.schema = schemaRoot;
    setRowJSON(formattedJSON);
  }, [formattedData]);

  columns.forEach((column) => {
    if (filter.hasOwnProperty(column.field)) {
      column.defaultFilter = filter[column.field];
    }
  });

  return (
    <>
      <Button sx={{ mb: 2 }} color="primary" variant="contained" onClick={() => addRow()}>
        Add rule
      </Button>
      <MaterialTable
        elevation={0}
        title={label}
        columns={columns}
        data={rowJSON}
        icons={tableIcons}
        debounceInterval={300}
        onFilterChange={(filterChanges) => {
          const result = filterChanges.reduce((acc, filterChange) => {
            const key = filterChange.column.field;
            const value = filterChange.value;
            acc[key] = value;
            return acc;
          }, {});
          setFilter({ ...result });
        }}
        actions={[
          {
            icon: () => <Edit color="info" />,
            tooltip: 'Edit',
            onClick: (e, rowData) => {
              editRow(rowData._id);
            },
          },
          {
            icon: () => <Delete color="error" />,
            tooltip: 'Delete',
            onClick: (e, rowData) => {
              if (window.confirm('Are you shure you want to delete?')) {
                deleteRow(rowData._id);
              }
            },
          },

          {
            icon: () => <FileCopy color="warning" />,
            tooltip: 'Duplicate',
            onClick: (e, rowData) => {
              if (window.confirm('You are about to duplicate this row')) {
                copyRow(rowData.tableData.id);
              }
            },
          },
        ]}
        {...(dataHasExceptions
          ? {
              parentChildData: (row, rows) => rows.find((a) => a._id === row.parentId),
            }
          : '')}
        autoResetPage={false}
        autoResetFilters={false}
        autoResetSortBy={false}
        options={{
          search: false,
          elevation: 0,
          emptyRowsWhenPaging: false,
          draggable: false,
          maxBodyHeight: 'calc( 100vh - 350px)',
          pageSize: 25,
          pageSizeOptions: [25, 100, 500, 1000],
          fontFamily: 'Italian-regular',
          filtering: true,
          searchFieldStyle: {
            fontSize: 16,
            fontFamily: 'Italian-regular',
          },
          filterCellStyle: {
            border: '1px solid #ddd',
            padding: '2px 8px 6px 6px',
            backgroundColor: '#FFF',
            fontSize: 16,
            fontFamily: 'Italian-regular',
          },
          headerStyle: {
            position: 'sticky',
            top: -1,
            border: '1px solid #ddd',
            backgroundColor: '#FFF',
            zIndex: '8',
            padding: '12px 8px',
            fontSize: 12,
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            fontFamily: 'Italian-regular',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
          },
          cellStyle: {
            verticalAlign: 'baseline',
            border: '1px solid #ddd',
            padding: 8,
            fontSize: 14,
            lineHeight: '16px',
            fontFamily: 'Italian-regular',
          },
          actionsCellStyle: {},
          rowStyle: (rowData) => ({
            paddingLeft: '1rem',
            backgroundSize: rowData.hasOwnProperty('parentId') ? '16px 16px' : '',
            backgroundImage: rowData.hasOwnProperty('parentId')
              ? 'repeating-linear-gradient(135deg, rgb(255 63 0 / 11%) 0px, rgb(255 111 48 / 0%) 1px, rgb(255 255 255) 0px, rgb(255, 255, 255) 50%)'
              : '',
          }),
        }}
        localization={{
          header: {
            actions: 'Edit',
          },
          body: {
            addTooltip: 'TRANSLATE TO YOUR LANGUAGE HERE',
          },
        }}
      />
    </>
  );
}
MaterialTableComponent.defaultProps = {
  label: null,
  dataKey: null,
  className: null,
  schemaRoot: {},
};

MaterialTableComponent.propTypes = {
  formattedData: PropTypes.array.isRequired,
  label: PropTypes.string,
  dataKey: PropTypes.string,
  className: PropTypes.string,
  schemaRoot: PropTypes.object,
};

export { MaterialTableComponent };
