import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Tabs as MuiTabs,
  Tab,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { Modal } from '../Modal';
import { DialogContent } from '@material-ui/core';

const countryList = [
  { value: 'no', label: 'Norway' },
  { value: 'se', label: 'Sweden' },
  { value: 'da', label: 'Denmark' },
  { value: 'nl', label: 'Nederlands' },
  { value: 'be', label: 'Belgium' },
  { value: 'fi', label: 'Finland' },
  { value: 'is', label: 'Iceland' },
];

export const Tabs = ({
  tabs,
  onClick,
  displayComponent,
  selectedTab,
  addDomain,
  deleteDomain,
}) => {
  const [tabToDelete, setTabToDelete] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [domainToAdd, setDomainToAdd] = useState('');
  const [domainToDelete, setDomainToDelete] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [deleteSelectOptions, setDeleteSelectOptions] = useState([]);

  useEffect(() => {
    const countriesOptions = cloneDeep(countryList).filter(
      (country) => !tabs.includes(country.value)
    );
    const countriesDeleteOptions = cloneDeep(countryList).filter((country) =>
      tabs.includes(country.value)
    );
    setSelectOptions(countriesOptions);
    setDeleteSelectOptions(countriesDeleteOptions);
  }, [tabs]);

  const handleClickTab = (ev, tab) => {
    ev.preventDefault();
    if (tab === 'add') {
      setShowAddDomainModal((lastValue) => !lastValue);
    } else if (tab === 'delete') {
      setShowDeleteModal((lastValue) => !lastValue);
    } else {
      onClick(tab);
    }
  };

  const handleCreate = () => {
    setShowAddDomainModal(true);
  };

  const handleCreateModalClose = () => {
    setDomainToAdd('');
    setShowAddDomainModal(false);
  };

  const handleCreateTab = () => {
    addDomain(domainToAdd);
    setShowAddDomainModal(false);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setTabToDelete('');
  };

  const handleDeleteTab = () => {
    deleteDomain(domainToDelete);
    setShowDeleteModal(false);
    setTabToDelete('');
    // DELETE
  };

  const getCountry = (abr) => {
    const name = countryList.find((country) => country.value === abr);
    return name ? name.label : 'UNKNOWN';
  };

  const tabSxStyles = {
    minWidth: 0,
    flexGrow: '1',
    fontFamily: 'Italian-regular',
    fontWeight: '600',
  };

  return (
    <Box sx={{ mb: 2 }}>
      <MuiTabs value={selectedTab} onChange={handleClickTab} sx={{ p: 1, mb: 2 }}>
        {tabs.map((tab, i) => (
          <Tab value={tab} key={`tab-${i}`} label={tab} sx={tabSxStyles} />
        ))}
        <Tab value="add" label="Add" sx={{ color: 'success.main', ...tabSxStyles }} />
        <Tab
          value="delete"
          color="primary"
          sx={{ color: 'error.main', ...tabSxStyles }}
          label="Delete"
        />
      </MuiTabs>

      {displayComponent}

      <Dialog open={showAddDomainModal} onClose={() => handleCreateModalClose()}>
        <DialogTitle>Select a domain you want to add specific settings to</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="demo-simple-select-helper-label">Select...</InputLabel>
            <Select
              label="Select..."
              value={domainToAdd}
              onChange={(e) => setDomainToAdd(e.target.value)}
            >
              {selectOptions.map((country, i) => (
                <MenuItem key={`country-option-${i}`} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Button variant="contained" onClick={handleCreateTab} sx={{ marginRight: 2 }}>
              Create tab
            </Button>
            <Button variant="text" onClick={handleCreateModalClose}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onClose={() => handleDeleteModalClose()}>
        <DialogTitle>Select a domain you want DELETE</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="demo-simple-select-helper-label">Select...</InputLabel>
            <Select
              label="Select..."
              value={domainToDelete}
              onChange={(e) => setDomainToDelete(e.target.value)}
            >
              {deleteSelectOptions.map((country, i) => (
                <MenuItem key={`country-option-${i}`} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {domainToDelete && (
            <Typography variant="body1" sx={{ my: 1 }}>
              This action is irreversable. Are you sure you want to proceed?
            </Typography>
          )}
          <Box sx={{ mt: 2, mb: 1 }}>
            <Button variant="contained" onClick={handleDeleteTab} sx={{ marginRight: 2 }}>
              Delete tab
            </Button>
            <Button variant="text" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

Tabs.defaultProps = {
  displayComponent: null,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  displayComponent: PropTypes.node,
  addDomain: PropTypes.func.isRequired,
  deleteDomain: PropTypes.func.isRequired,
};
